"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBatchResponseIStockMovementEntity = void 0;
const istock_movement_entity_1 = require("./istock-movement-entity");
function parseBatchResponseIStockMovementEntity(data) {
    if (data.records !== null && typeof data.records === 'object')
        data.records = data.records.map((x) => (0, istock_movement_entity_1.parseIStockMovementEntity)(x));
    return data;
}
exports.parseBatchResponseIStockMovementEntity = parseBatchResponseIStockMovementEntity;
